const PAISES = require('./src/data/paises.json')

exports.onClientEntry = async () => {
  const url = window.location.pathname
  const arrUrl = url.split('/')

  if (!PAISES.includes(arrUrl[1])) {
    const newUrl =
      location.protocol + '//' + location.host + '/br' + location.pathname
    window.localStorage.setItem('gatsby-i18next-language', 'br')
    window.location.href = newUrl
  }

  if (
    window.location.pathname === '/' &&
    !window.localStorage.getItem('gatsby-i18next-language')
  ) {
    window.localStorage.setItem('gatsby-i18next-language', 'br')
    window.location.pathname = `/br`
  }

  if (
    window.location.pathname === '/cl/' ||
    window.location.pathname === '/cl'
  ) {
    window.localStorage.setItem('gatsby-i18next-language', 'cl')
  }

  if (
    window.location.pathname === '/co/' ||
    window.location.pathname === '/co'
  ) {
    window.localStorage.setItem('gatsby-i18next-language', 'co')
  }

  if (typeof IntersectionObserver === "undefined") {
   await import("intersection-observer")
  }
}
